import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Menu } from "react-data-grid-addons";

class ToDoListDataGrid extends Component {
  render() {
    const { ContextMenu, MenuItem, ContextMenuTrigger } = Menu;
    const {
      columns,
      rows, 
      openProjectCase,
      copyCaseData,
      copyCaseLink,
      copyCaseTitle,
      addNewCase,
      sortRows
    } = this.props;

    const ToDoListContextMenu = ({
      id,
      rowIdx, 
      onRowEdit, 
      onRowCopyData, 
      onRowCopyLink, 
      onRowCopyTitle, 
      onRowAddNew 
    }) => {
      return (
        <ContextMenu id={id}>
          <MenuItem data={{ rowIdx }} onClick={onRowEdit}>
            Edit
          </MenuItem>
          <MenuItem data={{ rowIdx }} onClick={onRowCopyData}>
            Copy Link and Title
          </MenuItem>
          <MenuItem divider/>
          <MenuItem data={{ rowIdx }} onClick={onRowCopyLink}>
            Copy Link
          </MenuItem>
          <MenuItem data={{ rowIdx }} onClick={onRowCopyTitle}>
            Copy Title
          </MenuItem>
          <MenuItem divider/>
          <MenuItem data={{ rowIdx }} onClick={onRowAddNew}>
            Add new case
          </MenuItem>
        </ContextMenu>
      );
    }

    return (
      <div className="item-list section">
        <div className="ctr-data-grid">
            <ReactDataGrid
            columns={columns}
            rowGetter={i => rows[i]}
            rowsCount={rows.length}
            RowsContainer={ContextMenuTrigger}
            contextMenu={
                <ToDoListContextMenu
                    onRowEdit={(e, { rowIdx }) => openProjectCase({ caseId: rows[rowIdx].numericId })}
                    onRowCopyData={(e, { rowIdx }) => copyCaseData(rowIdx)}
                    onRowCopyLink={(e, { rowIdx }) => copyCaseLink(rowIdx)}
                    onRowCopyTitle={(e, { rowIdx }) => copyCaseTitle(rowIdx)}
                    onRowAddNew={(e, { rowIdx }) => addNewCase(rowIdx)}
                />
            }
            onGridSort={(sortColumn, sortDirection) => sortRows(sortColumn, sortDirection, false)}
            />
        </div>
      </div>
    );
  } 
}

export default ToDoListDataGrid;