import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './authReducer';
import projectCaseListReducer from './projectCaseListReducer';
import notificationListReducer from './notificationListReducer';
import dataGridReducer from './dataGridReducer';

const rootReducer = combineReducers({
	auth: authReducer,
	firestore: firestoreReducer,
	firebase: firebaseReducer,
	projectCaseList: projectCaseListReducer,
	notificationList: notificationListReducer,
	dataGrid: dataGridReducer
})

export default rootReducer;