import React from 'react';
import { withAuthentication } from '../hoc';
import CreateProjectCaseCardContainer from '../containers/CreateProjectCaseCardContainer';

const CreateProjectCasePage = () =>
	<div className="container">
		<CreateProjectCaseCardContainer />
	</div>


export default withAuthentication(CreateProjectCasePage);
