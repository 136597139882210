import React, { Component } from 'react';
import AuthService from '../../services/authService'

const withRoles = (Wrapped, permissionRoles=['user']) => {
  const { getRoles } = new AuthService();

  return class extends Component {
    state = {
      admin: false,
      user: false
    };

    componentDidMount() {
      getRoles()
        .then(({ admin, user }) => {
          this.setState({ admin, user })
        })
    }

    render() {
      return <Wrapped {...this.props} roles={this.state}/>;
    }
  }
};

export default withRoles;