const notificationsRequested = () => {
  return { type: 'FETCH_NOTIFICATIONS_REQUEST' };
};

const notificationsLoaded = (notifications) => {
  return { type: 'FETCH_NOTIFICATIONS_SUCCESS', payload: notifications };
};

const notificationsError = (error) => {
  return { type: 'FETCH_NOTIFICATIONS_FAILURE', payload: error };
};

export const fetchNotifications = (cloudTrackerService) => () => (dispatch) => {
  dispatch(notificationsRequested());
  cloudTrackerService.getNotifications()
    .then((data) => dispatch(notificationsLoaded(data)))
    .catch((err) => dispatch(notificationsError(err)));
};