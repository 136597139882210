import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signUp } from '../../store/actions/authActions';
import SignUpFormContainer from './SignUpForm';

export class SignUp extends Component {

	render() {
		const { auth, signUp, authError } = this.props;
		if (auth.uid) return <Redirect to='/' />

		return (
			<SignUpFormContainer 
				submitAction={signUp}
				authError={authError}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		signUp: (newUser) => dispatch(signUp(newUser))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
