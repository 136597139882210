import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from '../hoc';
import * as caseFormPropertiesData from '../../config/constants';
import { fetchProjectCaseForm, editProjectCaseByForm, checkValidFormData } from '../../store/actions/projectCaseFormActions';
import ProjectCaseCardContainer from './ProjectCaseCardContainer';

export class EditProjectCaseCardContainer extends Component {

  render() {
    const { fetchProjectCaseForm, editProjectCase } = this.props;

    const cardData = { 
      title: caseFormPropertiesData.EDIT_PROJECT_CASE_PAGE_TITLE, 
      action: caseFormPropertiesData.EDIT_PROJECT_CASE_PAGE_ACTION
    }

    return (
      <ProjectCaseCardContainer
        cardData={cardData}
        fetchAction={() => fetchProjectCaseForm(this.props.numericId)}
        submitAction={editProjectCase}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return { numericId: ownProps.match.params.numericId }
};

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    editProjectCase: editProjectCaseByForm(cloudTrackerService),
    fetchProjectCaseForm: fetchProjectCaseForm(cloudTrackerService),
    checkValidFormData: checkValidFormData
  }, dispatch)
}

export default compose(
  withCloudTrackerService(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditProjectCaseCardContainer);