import React, { Component } from 'react';
import M from "materialize-css";

class FloatButton extends Component {
  componentDidMount() {
    M.AutoInit();
  }

  render() {
    const { addNewCase } = this.props
    return (
      <div className="fixed-action-btn">
      <span className="btn-floating btn-large blue" onClick={addNewCase}>
        <i className="large material-icons">add</i>
      </span>
      {/* <ul>
        <li><span className="btn-floating green" onClick={addNewCase}><i className="material-icons">playlist_add</i></span></li>
      </ul> */}
    </div>
    );
  }
}

export default FloatButton;