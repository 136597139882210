import React from 'react';
import { withAuthentication } from '../hoc';
import ProductBacklogDataGridContainer from '../containers/ProductBacklogDataGridContainer';

const ProductBacklogPage = () =>
	<div className="container">
		<ProductBacklogDataGridContainer />
	</div>


export default withAuthentication(ProductBacklogPage);