import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Redirect } from 'react-router-dom';

export class SignIn extends Component {
	state = {
		email: '',
		password: ''
	}

	handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.signIn(this.state)
	}

	render() {
		const { auth, authError } = this.props;
		if (auth.uid) return <Redirect to='/' />

		return (
			<div className="container">
				<div className="row">
					<div className="col s6 m6 offset-m3">
						<form onSubmit={this.handleSubmit} className="white">
						<h5 className="grey-text text-darken-3">Sign In</h5>
						<div className="input-field">
							<label htmlFor="email" className="active">Email</label>
							<input type="email" id="email" onChange={this.handleChange}/>
						</div>
						<div className="input-field">
							<label htmlFor="password" className="active">Password</label>
							<input type="password" id="password" onChange={this.handleChange}/>
						</div>
						<div className="input-field">
							<button className="btn pink darken-2 z-depth-0">Login</button>
							<div className="red lighten-5 pink-text text-darken-2 center">
								{ authError ? <p>{authError}</p> : null }
							</div>
						</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		signIn: (creds) => dispatch(signIn(creds))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
