import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import { Menu } from "react-data-grid-addons";

class SprintBacklogDataGrid extends Component {
  render() {
    const { ContextMenu, MenuItem, ContextMenuTrigger } = Menu;
    const {
      rows,
      expandedRows,
      sprintBacklogGridColumns,
      onCellExpand,
      getSubRowDetails,
      onCaseOpen,
      setSelectedRowIndx
    } = this.props;

    const SprintBacklogContextMenu = ({ id, rowIdx, onCaseOpen }) => {
      if (!rows[rowIdx] || !rows[rowIdx].numericId ) return null;
      return (
        <ContextMenu id={id}>
          <MenuItem onClick={onCaseOpen}>
            Edit
          </MenuItem>
        </ContextMenu>
      );
    }

    return (
      <div className="item-list section">
        <div className="ctr-data-grid sprint-backlog-data-grid">
          <ReactDataGrid
            columns={sprintBacklogGridColumns}
            rowGetter={i => rows[i]}
            rowsCount={rows.length}
            minHeight={800}
            ref={grid => this.grid = grid}
            getSubRowDetails={getSubRowDetails(expandedRows)}
            onCellExpand={args => onCellExpand({ ...args, rows, expandedRows })}
            onCellSelected={({ rowIdx }) => { setSelectedRowIndx(rowIdx) }}
            RowsContainer={ContextMenuTrigger}
            contextMenu={
              <SprintBacklogContextMenu
                onCaseOpen={onCaseOpen}
              />
            }
          />
        </div>
      </div>
    );
  }
}

export default SprintBacklogDataGrid;