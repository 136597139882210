import React from 'react';

const ErrorIndicator = () => {
  return (
    <div className="preloader-centering">
      <img src='images/error-indicator.png' alt='error-indicator'/>
    </div>
  )
};

export default ErrorIndicator;
