import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from '../hoc';
import { fetchUnconfirmedUsers, confirmUser, deleteUser } from '../../store/actions/adminActions'
import PreloaderCircular from '../PreloaderCircular';
import ErrorIndicator from '../ErrorIndicator';

class UserConfirmationList extends Component {
  _isMounted = false;

  state = {
    userList: [],
    loading: true,
    error: false
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.fetchUnconfirmedUsers()
    .then((userList) => {
      if (!this._isMounted) return;

      this.setState({
        userList,
        loading: false
      })
    })
    .catch((err) => this.setState({ error: true }))
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
	render() {
    const { userList, loading, error } = this.state;
    const { confirmUser, deleteUser } = this.props;
    if (error) { return <ErrorIndicator />; }
    if (loading) { return <PreloaderCircular />; }

    const onUserItem = ({ userAction, uid, idx }) => {
      if (this.state.userList[idx].isDisable) return;

      disableUserItem(idx);
      userAction(uid)
        .then(() => {
          removeUserItem(idx)
        })
        .catch((err) => this.setState({ error: true }))
    }

    const disableUserItem = (idx) => {
      const { userList } = this.state;

      const newUserList = [
        ...userList.slice(0, idx),
       { ...userList[idx], isDisable: true },
        ...userList.slice(idx + 1)
      ];
      
      this.setState({ userList: newUserList})
    }

    const removeUserItem = (idx) => {
      const { userList } = this.state;

      const newUserList = [
        ...userList.slice(0, idx),
        ...userList.slice(idx + 1)
      ];

      this.setState({ userList: newUserList})
    }

    return (
      <div className="container">
        <div className="row section">
          <div className="project-card-title-container">
              <div className="col title">USER CONFIRMATION</div>
          </div>
          <div>
            <ul className="collection with-header user-confirmation-list">
              {userList.map((user, index) => {
                console.log('user', user)
                return (
                  <li className="collection-item" key={user.uid}>
                    <div>{user.email}
                      <span className="secondary-content">
                        <i disabled className={"material-icons " + (user.isDisable ? "grey-text text-lighten-1" : "")} onClick={() => onUserItem({ userAction: confirmUser, uid: user.uid, idx: index })}>how_to_reg</i>
                        <i className="material-icons grey-text text-lighten-1"  onClick={() => onUserItem({ userAction: deleteUser, uid: user.uid, idx: index })}>cancel</i>
                      </span>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    )
	}
}

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    fetchUnconfirmedUsers: fetchUnconfirmedUsers(cloudTrackerService),
    confirmUser: confirmUser(cloudTrackerService),
    deleteUser: deleteUser(cloudTrackerService)
  }, dispatch)
}

export default compose(
  withCloudTrackerService(),
  connect(null, mapDispatchToProps)
)(UserConfirmationList);