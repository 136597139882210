import React from 'react';
import ModalNotification from './ModalNotification'

const ModalDeleteNotification = ({ onAction }) => {
  const title = 'Delete Case';
  const content = 'Are you sure you want to remove this case?';

  return (
    <ModalNotification onAction={onAction} title={title} content={content} />
  )
}

export default ModalDeleteNotification;