import { getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';

export default class CloudTrackerService {

  getProjectCases() {
    const firestore = getFirestore();
    const cases = [];

    return firestore.collection('projectCases').orderBy('createAt', 'asc').get()
    .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
        cases.push({ ...doc.data(), id: doc.id })
      })
      return cases;
    })
    .catch(err => console.error(err))
  }

  updateProjectCase(projectCase) {
    const firestore = getFirestore();
    return firestore.collection('projectCases').doc(projectCase.id).update(projectCase);
  }

  deleteProjectCase(projectCasesIds) {
    const firestore = getFirestore();
    let batch = firestore.batch();

    projectCasesIds.forEach((id) => {
      let ref = firestore.collection('projectCases').doc(id);
      batch.delete(ref);
    })

    return batch.commit();
  }

  createProjectCase(projectCase, profile) {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const authorId = firebase.auth().currentUser.uid;

    return firestore.collection('projectCases').orderBy('numericId', 'desc').limit(1).get()
		.then((querySnapshot) => {
      const newNumericId = querySnapshot.docs.length > 0 ? querySnapshot.docs[0].data().numericId + 1 : 1;
      const newProjectCase = {
        ...projectCase,
				authorFirstName: profile.firstName,
				authorLastName: profile.lastName,
				authorId: authorId,
				createAt: new Date(),
				updateAt: new Date(),
				numericId: newNumericId
      }

      return firestore.collection('projectCases').add(newProjectCase).then(({ id }) => {
        return { ...newProjectCase, id };
      });
    })
    .catch(err => console.error(err))
  }

  getUserCases() {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const uid = firebase.auth().currentUser.uid;

    const cases = [];
    return firestore.collection('projectCases').orderBy('createAt', 'desc').where('assignedTo', 'array-contains', uid).get()
    .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
        cases.push({ ...doc.data(), id: doc.id })
      })
      return cases;
    })
    .catch(err => console.error(err))
  }

  getCaseByNumericId(numericId) {
    const firestore = getFirestore();
    let projectCase;

    return firestore.collection('projectCases').where('numericId', '==', Number(numericId)).get()
    .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
        projectCase = { ...doc.data(), id: doc.id };
      })
      return projectCase;
    })
    .catch(err => console.error(err))
  }

  getNotifications() {
    const firestore = getFirestore();
    const notifications = [];

    return firestore.collection('notifications').limit(5).orderBy('time', 'desc').get()
    .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
        notifications.push({ ...doc.data(), id: doc.id })
      })
      return notifications;
    })
    .catch(err => console.error(err))
  }

  getUserList() {
    const firestore = getFirestore();
    const userList = [];

    return firestore.collection('users').orderBy('firstName', 'asc').get()
    .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
        const user = { ...doc.data(), id: doc.id };

        if (user.verified === true) { 
          user.fullName = user.firstName + ' ' + user.lastName
          userList.push(user)
        }
      })
      return userList;
    })
    .catch(err => console.error(err))
  }

  getUnconfirmedUserList() {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;

    if (user) {
      return user.getIdToken()
        .then(idToken => {
          const getUnconfirmUserList = firebase.functions().httpsCallable('getUnconfirmUserList');

          return getUnconfirmUserList({ idToken })
            .then(({ data }) => data)
            .catch(err => console.error(err))
        })
    }
  }

  confirmNewUser(uid) {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;

    if (user) {
      return user.getIdToken()
        .then(idToken => {
          const confirmNewUser = firebase.functions().httpsCallable('confirmNewUser');
          return confirmNewUser({ uid, idToken });
        })
    }
  }

  deleteUser(uid) {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser;

    if (user) {
      return user.getIdToken()
        .then(idToken => {
          const deleteUser = firebase.functions().httpsCallable('deleteUser');

          return Promise.all([
            deleteUser({ uid, idToken }),
            firestore.collection('users').doc(uid).delete()])
              .catch((err) => console.error('Error removing user: ', err))
        })
    }
  }
}