import React from 'react';
import ProjectCaseCardInfo from './ProjectCaseCardInfo';
import ProjectCaseCardSelectedProps from './ProjectCaseCardSelectedProps';

const ProjectCaseCard = ({ cardData, formData, formErrorExist, saving, handleSubmit, handleChange, formPropertiesData }) => {

  return (
    <div className="container create-case-form">
      <div className="row section">
        <div className="project-card-title-container">
            <div className="col title">{cardData.title}</div>
            <div className="col ctr">CTR-{formData.numericId ? formData.numericId : 'NEW'}</div>
        </div>
        <form onSubmit={handleSubmit} className="white">
          <div className="col s6 m6 left-form-column">
              <div className="input-field">
                <label htmlFor="title" className="active">Title</label>
                <input type="text" id="title" className={`${formErrorExist ? 'invalid' : null}`} value={formData.title} onChange={handleChange}/>
                { formErrorExist ? <span className="pink-text text-darken-2 helper-text">required field </span> : null }
              </div>

              <div className="input-field">
                <label htmlFor="comments" className="active">Comments</label>
                <textarea id="comments" className='materialize-textarea' value={formData.comments} onChange={handleChange}></textarea>
              </div>
            
              <div className="input-field">
                <button className="btn pink darken-2 z-depth-0" disabled={saving}>{cardData.action}</button>
              </div>
          </div>
          <div className="col s5 m5 offset-m1 right-form-column">
            <ProjectCaseCardInfo formData={formData}/>
            <ProjectCaseCardSelectedProps
              formData={formData} 
              handleChange={handleChange} 
              formPropertiesData={formPropertiesData}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProjectCaseCard;
