import React from 'react';
import moment from 'moment';

const ProjectCaseCardInfo = ({formData}) => {
	const { id, authorFirstName, authorLastName, createAt, updateAt } = formData;
  if (!id) return null;

  return (
    <div className="item-details">
      <div className="card z-depth-0">
        <div className="row card-action grey lighten-4 grey-text">
          <div className="col s4 m4 right-align left-column">
            <div>Created by</div>
            <div>Created on:</div>
            <div>Updated on:</div>
          </div>
          <div className="col s8 m8 left-align right-column">
            <div>{`${authorFirstName} ${authorLastName}`}</div>
            <div>{moment(createAt.toDate()).format('MMMM Do YYYY, h:mm:ss a')}</div>
            <div>{moment(updateAt.toDate()).format('MMMM Do YYYY, h:mm:ss a')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCaseCardInfo;