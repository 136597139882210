import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from './hoc';
import moment from 'moment';
import { fetchNotifications } from '../store/actions/notificationActions';

import PreloaderCircular from './PreloaderCircular';
import ErrorIndicator from './ErrorIndicator';

const Notifications = ({ notifications }) => {
	return (
		<div className="section">
			<div className="card z-depth-0">
				<div className="card-content">
				<span className="card-title">Notifications</span>
					<ul className="notification">
						{	notifications && notifications.map(item => {
							return (
								<li key={item.id} style={{paddingBottom: '20px'}}>
									<p className="Black-text">{item.user}</p>
									<p className="teal-text text-darken-2">{item.content}</p>
									<div className="grey-text note-date">
										{moment(item.time.toDate()).fromNow()}
									</div>
								</li>
							)
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}

class NotificationsContainer extends Component {
	componentDidMount() {
		this.props.fetchNotifications();
	}

	render() {
		const { notifications, loading, error } = this.props
		if (loading) { return <PreloaderCircular />; }
		if (error) { return <ErrorIndicator />; }

		return <Notifications notifications={notifications}/>
	}
}

const mapStateToProps = ({ notificationList: { notifications, loading, error } }) => {
  return { notifications, loading, error };
};

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    fetchNotifications: fetchNotifications(cloudTrackerService),
  }, dispatch)
}


export default compose(
  withCloudTrackerService(),
  connect(mapStateToProps, mapDispatchToProps)
)(NotificationsContainer);