import _ from 'lodash';
import history from '../../history';
import { reject } from 'q';

const projectCasesRequested = () => {
  return { type: 'FETCH_PROJECT_CASES_REQUEST' };
};

const  projectCasesLoaded = (cases) => {
  return { type: 'FETCH_PROJECT_CASES_SUCCESS', payload: cases };
};

const  projectCasesError = (error) => {
  return { type: 'FETCH_PROJECT_CASES_FAILURE', payload: error };
};

const  projectCaseUpdateSuccess = (data) => {
  return { type: 'UPDATE_PROJECT_CASE_SUCCESS', payload: data };
};

const  projectCaseUpdateError = (data) => {
  return { type: 'UPDATE_PROJECT_CASE_FAILURE', payload: data };
};

const  projectCaseCreate = (data) => {
  return { type: 'CREATE_PROJECT_CASE_REQUEST', payload: data };
};

const  projectCaseCreateError = (data) => {
  return { type: 'CREATE_PROJECT_CASE_FAILURE', payload: data};
};

const projectCaseDeleted = () => {
	return { type: 'DELETE_PROJECT_CASE_SUCCESS' };
}

const projectCaseDeleteError = () => {
	return { type: 'DELETE_PROJECT_CASE_FAILURE' };
}



export const fetchAllProjectCases = (cloudTrackerService) => () => (dispatch, getState) => {
  dispatch(projectCasesRequested());
  return cloudTrackerService.getProjectCases()
    .then((projectCases) => {
			dispatch(projectCasesLoaded(projectCases))
			return _.cloneDeep(projectCases)
		})
	  .catch((err) => dispatch(projectCasesError(err)));
};

export const updateProjectCase = (cloudTrackerService) => (props) => (dispatch, getState) => {
	const { caseId, updated } = props;
	const { projectCaseList: { projectCases }} = getState();

	const newProjectCases = _.cloneDeep(projectCases);
	const updatedProjectCase = {
		id: caseId,
		...updated,
		updateAt: new Date()
	}

	return cloudTrackerService.updateProjectCase(updatedProjectCase)
		.then(() => {
			const projectCaseIndx = _.findIndex(projectCases, projectCase => projectCase.id === caseId);
			newProjectCases[projectCaseIndx] = Object.assign(newProjectCases[projectCaseIndx], updatedProjectCase);
			dispatch(projectCaseUpdateSuccess(newProjectCases));
			return updatedProjectCase;
		})
		.catch((err) => {
			dispatch(projectCaseUpdateError(err));
			return reject();
		})
};

export const createProjectCase = (cloudTrackerService) => (projectCase) => (dispatch, getState) => {
	const { projectCaseList: { projectCases }, firebase: { profile }} = getState();

	const parentId = projectCase.parentId === undefined ? null : projectCase.parentId;
	const newProjectCase = { ...projectCase, parentId };

	return cloudTrackerService.createProjectCase(newProjectCase, profile)
		.then(projectCase => {
			dispatch(projectCaseCreate([ ...projectCases, projectCase ]))
			return projectCase;
		})
		.catch((err) => dispatch(projectCaseCreateError(err)))
};

export const deleteProjectCase = (cloudTrackerService) => (projectCasesIds) => (dispatch, getState) => {
	if (!projectCasesIds || _.isEmpty(projectCasesIds)) return;
	
	return cloudTrackerService.deleteProjectCase(projectCasesIds)
		.then(() => dispatch(projectCaseDeleted()))
		.catch((err) => dispatch(projectCaseDeleteError(err)));
}

export const fetchUserCases = (cloudTrackerService) => () => (dispatch, getState) => {
  dispatch(projectCasesRequested());
  return cloudTrackerService.getUserCases()
    .then((projectCases) => {
			dispatch(projectCasesLoaded(projectCases))
			return _.cloneDeep(projectCases)
		})
    .catch((err) => dispatch(projectCasesError(err)));
};


export const openProjectCase = ({ caseId }) => (dispatch, getState) => {
	history.push('/project-cases/' + caseId);
};

export const copyCaseData = ({ rowIdx }) => (dispatch, getState) => {
	console.log('copyCaseData');
};

export const copyCaseLink = ({ rowIdx }) => (dispatch, getState) => {
	console.log('copyCaseLink');
};

export const copyCaseTitle = ({ rowIdx }) => (dispatch, getState) => {
	console.log('copyCaseTitle');
};

export const addNewCase = ({ rowIdx }) => (dispatch, getState) => {
	console.log('addNewCase');
};