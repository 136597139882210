const initState = {
	rows: [],
	expandedRows: {},
	selectedRowIndx: 0,
	selectedCell: {
		startCell:  { idx: 0, rowIdx: 0 },
		cursorCell: { idx: 0, rowIdx: 0 }
	},
	isHoldingCreation: false,
	isSearchResult: false,
	copyData: ''
};

const reactDataGridReducer = (state = initState, action) => {
	
	switch (action.type) {
		case 'INIT_DATA_GRID_ROWS':
			return {
				...state,
				rows: action.payload,
				isSearchResult: false
			};
		case 'UPDATE_CONTENT_DATA_GRID_ROWS_REQUEST':
			return {
				...state,
				rows: action.payload,
				isHoldingCreation: true
			};
		case 'UPDATE_CONTENT_DATA_GRID_ROWS_SUCCESS':
			return {
				...state,
				rows: action.payload,
				isHoldingCreation: false
			};
			case 'UPDATE_STATE_DATA_GRID_ROWS':
		
			if (!action.payload) return initState;
			const { rows=[], expandedRows={}, isSearchResult=false } = action.payload;

      return {
        ...state,
				rows: rows,
				expandedRows: expandedRows,
				isSearchResult: isSearchResult
			};

		case 'UPDATE_DATA_GRID_SELECTED_ROW_INDEX':
			return {
				...state,
				selectedRowIndx: action.payload
			};

		case 'UPDATE_DATA_GRID_SELECTED_CELL':
			return {
				...state,
				selectedCell: action.payload
			};

		case 'UPDATE_DATA_GRID_HOLD_CREATING_ROW':
			return {
				...state,
				selectedRowIndx: action.payload
			};

		case 'UPDATE_COPY_DATA':
			return {
				...state,
				copyData: action.payload
			}

		default:
			return state;
	}
}

export default reactDataGridReducer;