import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as caseFormPropertiesData from '../../config/constants';
import { checkValidFormData } from '../../store/actions/projectCaseFormActions';
import PreloaderCircular from '../PreloaderCircular';
import ErrorIndicator from '../ErrorIndicator';
import ProjectCaseCard from '../projectCases/ProjectCaseCard';
import _ from 'lodash';

export class ProjectCaseCardContainer extends Component {
  _isMounted = false;

  state = {
    projectCase: {
      title: ''
    },
    userList: [],
    loading: true,
    saving: false,
    invalid: false,
    message: '',
    error: null
  }

	componentDidMount() {
    this._isMounted = true;

    this.props.fetchAction()
    .then(({ projectCase, userList }) => {
      if (!this._isMounted) return;
      
      this.setState({
        projectCase,
        userList,
        loading: false
      })
    })
    .catch((err) => this.setState({ error: true }))
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e) => {
    const newValue = _.isArray(this.state.projectCase[e.target.id])
    ? this.createNewArrValue(e.target.options)
    : e.target.value;


    const projectCase = { ...this.state.projectCase, [e.target.id]: newValue };
    this.setState({ projectCase });
  }

  createNewArrValue = (options) => {
    let values = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }

    return values;
  }

	handleSubmit = (e) => {
    e.preventDefault();

    if (this.props.checkValidFormData(this.state.projectCase)) {
      this.props.submitAction(this.state.projectCase)
        .then(() => this.setState({ saving: true }))
        .catch(() => this.setState({ error: true }))
    } else {
      this.setState({ invalid: true });
    }
	}

  render() {
    const { cardData } = this.props;
    const {
      userList, 
      loading,
      saving,
      invalid, 
      error
    } = this.state;

    if (loading) { return <PreloaderCircular />; }
    if (error) { return <ErrorIndicator />; }

    return (
      <ProjectCaseCard
        cardData={cardData}
        formData={this.state.projectCase}
        formErrorExist={invalid}
        saving={saving}
        handleSubmit={this.handleSubmit}
				handleChange={this.handleChange}
				formPropertiesData={{ ...caseFormPropertiesData, userList }}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    checkValidFormData: checkValidFormData
  }, dispatch)
}

export default connect(null, mapDispatchToProps)(ProjectCaseCardContainer);