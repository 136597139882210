import React from 'react';
import { withAuthentication } from '../hoc';
import Notifications from '../Notifications';
import ToDoListDataGridContainer from '../containers/ToDoListDataGridContainer';

const ToDoListPage = () =>
	<div className="container">
		<div className="row">
			<div className="col s12 m9">
				<ToDoListDataGridContainer />
			</div>
			<div className="col s12 m3">
				<Notifications />
			</div>
		</div>
	</div>

export default withAuthentication(ToDoListPage);