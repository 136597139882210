import React from 'react';
import { NavLink } from 'react-router-dom';

const SignedOutLink = () => {
	return (
		<ul className="right">
			<li><NavLink to='/signup' className="amber-text text-lighten-3">SIGN UP</NavLink></li>
			<li><NavLink to='/signin'>LOG IN</NavLink></li>
		</ul>
	);
}

export default SignedOutLink;