import React from 'react';

const ModalNotification = ({ title, content, onAction }) => {
  return (
    <div id="modalCaseDelete" className="modal">
      <div className="modal-content">
        <h4>{title}</h4>
        <p>{content}</p>
      </div>
      <div className="modal-footer">
        <button className="btn modal-close btn-flat" onClick={onAction}>Agree</button>
        <button className="btn modal-close btn-flat">Cancel</button>
      </div>
    </div>
  )
}

export default ModalNotification;