import React from 'react';

const {
  Provider: CloudTrackerServiceProvider,
  Consumer: CloudTrackerServiceConsumer
} = React.createContext();

export {
  CloudTrackerServiceProvider,
  CloudTrackerServiceConsumer
};
