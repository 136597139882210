import { getFirebase } from 'react-redux-firebase';
import { reject } from 'q';

export default class AuthService {
  getRoles = () => {
    const firebase = getFirebase();

    return firebase.auth().currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      return {
          admin: !!idTokenResult.claims.admin, 
          user: !!idTokenResult.claims.user 
      };
    })
    .catch((error) => {
      console.error(error);
    });
  }

  getAuth = () => {
    const firebase = getFirebase();

    if (firebase.auth().currentUser === null) return reject(); 
    const { uid, emailVerified, email } = firebase.auth().currentUser;

    return this.getRoles()
    .then(({ admin, user }) => {
      return {
        uid,
        emailVerified,
        email,
        roles: { admin, user }
      };
    })
    .catch((error) => {
      console.error(error);
    });
  }

  createAdmin = () => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;

    this.checkAppAdmin()
      .then(({ data }) => {
        if (data.adminExists === false && user) {
          return user.getIdToken()
            .then(idToken => {
              const createAdminUser = firebase.functions().httpsCallable('createAdmin');
              return createAdminUser({ idToken })
                .then(() => {
                  firebase.auth().currentUser.getIdToken(true)
                    .then(() => window.location.reload());
                });
            })
        }
      })
  }

  checkAppAdmin = () => {
    const firebase = getFirebase();
    const checkAdmin = firebase.functions().httpsCallable('checkAppAdmin');
    return checkAdmin();
  }
}