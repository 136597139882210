import React from 'react';
import { withAuthentication } from '../hoc';
import UserConfirmationList from '../admin/UserConfirmationList';

const AdminPage = () =>
	<div className="container">
		<div className="row">
			<div className="col s12 m12">
				<UserConfirmationList />
			</div>
		</div>
	</div>

export default withAuthentication(AdminPage, ['user', 'admin']);