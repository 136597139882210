import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/header/Navbar';
import ToDoListPage from './components/pages/ToDoListPage';
import EditProjectCasePage from './components/pages/EditProjectCasePage';
import CreateProjectCasePage from './components/pages/CreateProjectCasePage';
import SprintBacklogPage from './components/pages/SprintBacklogPage';
import ProductBacklogPage from './components/pages/ProductBacklogPage';
import AdminPage from './components/pages/AdminPage';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import SignUpAsAdmin from './components/auth/SignUpAsAdmin';
import NotFoundPage from './components/pages/NotFoundPage';
import history from './history';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Navbar />
          <Switch>
            <Route path='/' exact component={ToDoListPage}></Route>
            <Route path='/signin' component={SignIn}></Route>
            <Route path='/signup' component={SignUp}></Route>
            <Route path='/admin/create' component={SignUpAsAdmin}></Route>
            <Route path='/project-cases/:numericId' exact component={EditProjectCasePage}></Route>
            <Route path='/create' component={CreateProjectCasePage}></Route>
            <Route path='/sprint-backlog' component={SprintBacklogPage}></Route>
            <Route path='/product-backlog' component={ProductBacklogPage}></Route>
            <Route path='/admin' component={AdminPage}></Route>
            <Route component={NotFoundPage}></Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
