import _ from 'lodash';
import moment from 'moment';

// GET SUBROW DETAILS
export const getSubRowDetails = (expandedRows, fieldName='title') => rowItem => {
  const isExpanded = expandedRows[rowItem.id]
    ? expandedRows[rowItem.id]
    : false;

  return {
    group: rowItem.subCases && rowItem.subCases.length > 0,
    expanded: isExpanded,
    children: rowItem.subCases,
    field: fieldName,
    treeDepth: rowItem.treeDepth || 0,
    siblingIndex: rowItem.siblingIndex,
    numberSiblings: rowItem.numberSiblings
  };
};

// GET EXPANDED SUBCASES OF CASE
export const getExpandedSubRows = (parentCase, parentTreeDepth=0, expandedRows, subRows=[]) => {
  const treeDepth = parentTreeDepth + 1;
  _.each(parentCase.subCases, (subCase, index) => {
    const newCase = { 
      ...subCase,
      treeDepth: treeDepth, 
      siblingIndex: index, 
      snumberSiblings: parentCase.subCases.length
    }
    
    subRows.push(newCase)
    if (expandedRows[subCase.id]) {
      subRows = getExpandedSubRows(subCase, treeDepth, expandedRows, subRows)
    }
  })

  return subRows;
}

// CREATE TREE OF CASES AND SUBCASES
export const buildTree = (data, parent) => {
  let result = [];
  const parentId = parent && parent.id ? parent.id : null;

  const projectCases = _.filter(data, function(value){
      return value.parentId === parentId;
  });

  if (!_.isEmpty(projectCases)) {
    _.each(projectCases, function(projectCase) {
      let copyProjectCase = { ...projectCase };
      const subCases = buildTree(data, projectCase);

      if(!_.isEmpty(subCases)){
        copyProjectCase = { ...copyProjectCase, subCases: subCases };
      }

      result = [ ...result, { ...copyProjectCase }];
    });
  }

  return result;
}

// CREATE ROWLIST OF EXPANDED CASES WITH TREE DEPTH
export const buildRows = (data, expandedRows={}) => {
  const projectCases = data.slice();
  const baseRows = _.filter(projectCases, function(value) {
      return value.parentId === null;
  });

  const result = [...baseRows];

  if (!_.isEmpty(baseRows)) {
    _.each(baseRows, function(row) {
      if (!expandedRows[row.id]) return;

      const expandedSubRows = getExpandedSubRows({ ...row }, 0, expandedRows);
      const rowIndx = _.findIndex(result, (r) => r.id === row.id);
      result.splice(rowIndx + 1, 0, ...expandedSubRows);
    });
  }

  return result;
}

// COUNT SUBCASES OF CATEGORY
export const countSkippedRows = (parentCase, expandedRows, count = 0) => {
  _.each(parentCase.subCases, (subCase) => {
    count++
    if (expandedRows[subCase.id]) {
      count = countSkippedRows(subCase, expandedRows, count)
    }
  })

  return count;
}

// CREATE CASES TREE WITH SUBCASES BY SPRINT
export const createSprintBacklogData = (caseProjectList, caseSprintList) => {
	if (!caseProjectList) return [];

  const caseGroupsBySprint = _.groupBy(caseProjectList, 'sprint');
	caseSprintList.map((sprint) => {
		sprint.subCases = caseGroupsBySprint[sprint.value] || [];
		sprint.title = sprint.value + ': ' + moment(sprint.endAt).format('MMMM Do')
		return sprint;
	})

	return caseSprintList;
}

// GET NEW PARENT CASE ID IN LOWER TREE DEPTH 
export const getLowerParentCaseId = (rows, selectedRowIndx) => {  
  const newRows = _.reverse(rows.slice(0, selectedRowIndx));
  const newParentCase = newRows.find((row) => row.threeDepth === rows[selectedRowIndx].threeDepth && row.parentId === rows[selectedRowIndx].parentId);
  return newParentCase ? newParentCase.id : rows[selectedRowIndx].parentId;
}

// GET NEW PARENT CASE ID IN HIGHER TREE DEPTH
export const getHigherParentCaseId = (rows, selectedRowIndx) => {
  const selectedCase = rows.slice(selectedRowIndx, selectedRowIndx + 1)[0];
  if (!selectedCase.parentId) return null;

  const parentCase = rows.find((row) => row.id === selectedCase.parentId);
  return parentCase.parentId ? parentCase.parentId : null;
}

// UPDATE SUB CASES OF ALL CASE PARENTS OF THIS TREE BRANCH
export const updateParentsSubCase = (rows, row, selectedRowIndx) => {
  let newRows = [ ...rows ];
  const selectedCase = { ...row };

  if (selectedCase && selectedCase.parentId) {
    const parentCaseIndx = _.findIndex(newRows, row => row.id === selectedCase.parentId);

    // the parent was deleted too
    if (parentCaseIndx === -1) return newRows;

    const selectedSubCaseIndx = _.findIndex(newRows[parentCaseIndx].subCases, ({ id }) => id === selectedCase.id);
      
    if (selectedSubCaseIndx !== -1 && selectedRowIndx !== -1) { // update case
      newRows[parentCaseIndx].subCases[selectedSubCaseIndx] = { ...selectedCase };

    } else if (selectedSubCaseIndx !== -1 && selectedRowIndx === -1) { // delete case
      newRows[parentCaseIndx].subCases = [
        ...newRows[parentCaseIndx].subCases.slice(0, selectedSubCaseIndx),
        ...newRows[parentCaseIndx].subCases.slice(selectedSubCaseIndx + 1)
      ];

      if (newRows[parentCaseIndx].subCases.length === 0) {
        delete newRows[parentCaseIndx].subCases;
      }

    } else {
      newRows[parentCaseIndx].subCases = [ ...newRows[parentCaseIndx].subCases, { ...selectedCase }]; // add new case
    }

    if (newRows[parentCaseIndx].parentId) {
      newRows = updateParentsSubCase(rows, newRows[parentCaseIndx], parentCaseIndx);
    }
  }

  return newRows;
}

// FILTER CASE LIST BY SEARCH TEXT
export const caseFilterByText = (filteredCases, columns, searchText) => {
  if (_.isEmpty(filteredCases)) return filteredCases;

  /*
  * Search cases by case columns
  * use format - key:"value"
  */

  let filteredText = _.clone(searchText).trim().toLowerCase();
  let caseProps = columns.map((column) => column.name.toLowerCase()).join('|');
  const regexp = RegExp(`(${caseProps}):{1}"{1}[\\w\\s]*"{1}`, 'gi');
  const keySearch = filteredText.match(regexp);

  if (!_.isEmpty(keySearch)) {
    keySearch.forEach((text) => {
      const key = text.match(/(\w*):/i)[1];
      const value = text.match(/:"([a-zA-Z0-9_ ]*)"/i)[1];

      filteredCases = filteredCases.filter((projectCase) => {
        if (_.isEmpty(projectCase[key]) || _.isEmpty(value)) return false;
        return projectCase[key].toLowerCase() === value;
      });

      filteredText = filteredText.replace(text, '').trim();
    })
  }

  // Search cases by title
  filteredCases = filteredCases.filter(({ title='' }) => {
    if (_.isEmpty(filteredText)) return true;

    filteredText = filteredText.replace(/\s+/g, " ");
    return title.toLowerCase().indexOf(filteredText) !== -1;
  });

  return filteredCases;
}

// GET SUBCASES IDS LIST
export const getSubCasesIds = (subCases, ids=[]) => {
  subCases.forEach((subCase) => {
    ids.push(subCase.id);
    if (subCase.subCases) {
      getSubCasesIds(subCase.subCases, ids)
    }
  })

  return [...new Set(ids)];
}