import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from '../hoc';
import * as caseFormPropertiesData from '../../config/constants';
import { createProjectCaseForm, createProjectCaseByForm, checkValidFormData } from '../../store/actions/projectCaseFormActions';
import ProjectCaseCardContainer from './ProjectCaseCardContainer';

export class CreateProjectCaseCardContainer extends Component {
  render() {
    const { createProjectCase, createProjectCaseForm } = this.props;

    const cardData = {
      title: caseFormPropertiesData.CREATE_PROJECT_CASE_PAGE_TITLE, 
      action: caseFormPropertiesData.CREATE_PROJECT_CASE_PAGE_ACTION
    }

    return (
      <ProjectCaseCardContainer
        cardData={cardData}
        fetchAction={createProjectCaseForm}
        submitAction={createProjectCase}
      />
    )
  }
}

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    createProjectCase: createProjectCaseByForm(cloudTrackerService),
    createProjectCaseForm: createProjectCaseForm(cloudTrackerService),
    checkValidFormData: checkValidFormData
  }, dispatch)
}

export default compose(
  withCloudTrackerService(),
  connect(null, mapDispatchToProps)
)(CreateProjectCaseCardContainer);