const initState = {
	notifications: [],
	loading: true,
	error: null
};

const notificationListReducer = (state = initState, action) => {

	switch (action.type) {
		case 'FETCH_NOTIFICATIONS_REQUEST':
			return initState;

		case 'FETCH_NOTIFICATIONS_SUCCESS':
			return {
				notifications: action.payload,
        loading: false,
        error: null
			};

		case 'FETCH_NOTIFICATIONS_FAILURE': 
			return {
				notifications: [],
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
}

export default notificationListReducer;
