import React, { Component } from 'react';

class SearchBar extends Component {

  state = {
    searchText: ''
  }

  handleSubmit = (e) => {
		e.preventDefault();
		this.props.searchAction(this.state)
  }
  
  handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		})
	}

	render() {
    const { searchText } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="input-field col s12">
          <input type="text" id="searchText" value={searchText} onChange={this.handleChange} autoComplete="off"/>
          <label htmlFor="searchText" className="grey-text text-lighten-1">Type text to filter...</label>
          <i className="material-icons grey-text text-lighten-1 prefix">search</i>
        </div>
      </form>
    )
	}
}

export default SearchBar;