import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from '../hoc';
import { onCellExpand, fetchDataGridTreeBySprint, updateDataGridRows, onCaseOpen, setSelectedRowIndx } from '../../store/actions/dataGridActions';
import { getSubRowDetails } from '../../services/dataGridService';
import { caseSprintList, sprintBacklogGridColumns } from '../../config/constants';
import PreloaderCircular from '../PreloaderCircular';
import ErrorIndicator from '../ErrorIndicator';
import SprintBacklogDataGrid from '../dataGrids/SprintBacklogDataGrid';

class SprintBacklogDataGridContainer extends Component {
  componentDidMount() {
    this.props.fetchDataGridTreeBySprint(caseSprintList);
  }

  componentWillUnmount() {
    this.props.updateDataGridRows();
  }

	render() {
    const { loading, error, rows, expandedRows, onCellExpand, onCaseOpen, setSelectedRowIndx } = this.props;

    if (loading) { return <PreloaderCircular />; }
    if (error) { return <ErrorIndicator />; }

		return (
      <SprintBacklogDataGrid 
        rows={rows}
        expandedRows={expandedRows}
        sprintBacklogGridColumns={sprintBacklogGridColumns}
        onCellExpand={onCellExpand}
        setSelectedRowIndx={setSelectedRowIndx}
        getSubRowDetails={getSubRowDetails}
        onCaseOpen={onCaseOpen}
      />
		);
	}
}

const mapStateToProps = ({ projectCaseList: { loading, error }, dataGrid: { rows, expandedRows } }) => {
  return { loading, error, rows, expandedRows }
};

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    fetchDataGridTreeBySprint: fetchDataGridTreeBySprint(cloudTrackerService),
    updateDataGridRows: updateDataGridRows,
    setSelectedRowIndx: setSelectedRowIndx,
    onCellExpand: onCellExpand,
    onCaseOpen: onCaseOpen
  }, dispatch)
}

export default compose(
  withCloudTrackerService(),
  connect(mapStateToProps, mapDispatchToProps)
)(SprintBacklogDataGridContainer);