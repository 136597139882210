import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from '../hoc';
import { toDoListGridColumns } from '../../config/constants'
import { openProjectCase, copyCaseData, copyCaseLink, copyCaseTitle, addNewCase } from '../../store/actions/projectCaseActions';
import { fetchUserCaseRows } from '../../store/actions/dataGridActions';
import { sortRows } from '../../store/actions/dataGridActions';
import PreloaderCircular from '../PreloaderCircular';
import ErrorIndicator from '../ErrorIndicator';
import ToDoListDataGrid from '../dataGrids/ToDoListDataGrid';


class ToDoListDataGridContainer extends Component  {

  componentDidMount() {
    this.props.fetchRows();
  }

  render() {
    const { rows, loading, error, openProjectCase, sortRows } = this.props;

    if (loading) { return <PreloaderCircular />; }
    if (error) { return <ErrorIndicator />; }

    return (
      <ToDoListDataGrid 
        columns={toDoListGridColumns}
        rows={rows}
        openProjectCase={openProjectCase}
        copyCaseData={copyCaseData}
        copyCaseTitle={copyCaseTitle}
        addNewCase={addNewCase}
        sortRows={sortRows}
      />
    )
  }
}

const mapStateToProps = ({ projectCaseList: { holdingNewCase, loading, error }, dataGrid: { rows } }) => {
  return { rows, holdingNewCase, loading, error }
};

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    fetchRows: fetchUserCaseRows(cloudTrackerService),
    openProjectCase: openProjectCase,
    copyCaseData: copyCaseData,
    copyCaseLink: copyCaseLink,
    copyCaseTitle: copyCaseTitle,
    addNewCase: addNewCase,
    sortRows: sortRows
  }, dispatch)
}


export default compose(
  withCloudTrackerService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ToDoListDataGridContainer);