import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import EmailVerify from '../auth/EmailVerify';
import PreloaderCircular from '../PreloaderCircular';
import AuthService from '../../services/authService'

const withAuthentication = (Wrapped, permissionRoles=['user']) => {
  const { getAuth } = new AuthService();

  return class extends Component {

    initialState = {
      uid: null,
      emailVerified: null,
      email: null,
      roles: {
        admin: false,
        user: false
      },
    }

    state = {
      ...this.initialState,
      loading: true
    };

    componentDidMount() {
      getAuth()
        .then(({ uid, emailVerified, email, roles }) => {
          this.setState({
            uid: uid,
            emailVerified: emailVerified,
            email: email,
            roles: roles, 
            loading: false 
          })
        })
        .catch(err => {
          this.setState({ ...this.initialState, loading: false })
        });
    }

    render() {
      const { uid, emailVerified, email, roles: { user, admin }, loading } = this.state;

      if (this.props.history) {
        if (loading) return <PreloaderCircular />
        if (!uid) return <Redirect to='/signin' />
        if (uid && (!emailVerified || !user)) return <EmailVerify email={email} isUser={user} emailVerified={emailVerified} />
        if (permissionRoles.indexOf('admin') !== -1 && !admin) return <Redirect to='/' />
      }

      return <Wrapped {...this.props} roles={this.state.roles}/>;
    }
  }
};

export default withAuthentication;