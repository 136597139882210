import React, { Component } from 'react';

class EmailVerify extends Component {
  render() {
		const { email, emailVerified, isUser } = this.props;

		return (
			<div className="dashboard container">
				  <div className="row">
					<div className="col s6 m6 offset-m3">
						<div className="card blue-grey darken-1">
							<div className="card-content white-text">
								<span className="card-title">Next steps:</span>
								{ !emailVerified ? <p>{`Please, verify your email: ${email}`}</p> : null }
								{ !isUser ? <p>{`Your account is awaiting confirmation by admin.`}</p> : null }
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default EmailVerify;