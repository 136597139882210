import _ from 'lodash';
import { buildTree, buildRows, countSkippedRows, createSprintBacklogData, getSubCasesIds,
         getLowerParentCaseId, getHigherParentCaseId, updateParentsSubCase, getExpandedSubRows, caseFilterByText } from '../../services/dataGridService';
import { fetchAllProjectCases, fetchUserCases, openProjectCase, updateProjectCase, createProjectCase, deleteProjectCase } from './projectCaseActions';
import { productBacklogGridColumns } from '../../config/constants';
import * as caseFormPropertiesData from '../../config/constants';

const createDataGridRowsRequest = (rows) => {
  return { type: 'INIT_DATA_GRID_ROWS', payload: rows };
};

const updateStateDataGridRowsRequest = (rowsData) => {
  return { type: 'UPDATE_STATE_DATA_GRID_ROWS', payload: rowsData };
};

const updateContentDataGridCaseRequest = (data) => {
  return { type: 'UPDATE_CONTENT_DATA_GRID_ROWS_REQUEST', payload: data };
};

const updateContentDataGridCaseSuccess = (data) => {
  return { type: 'UPDATE_CONTENT_DATA_GRID_ROWS_SUCCESS', payload: data };
};

const setSelectedRowIndxRequest = (rowIndx) => {
  return { type: 'UPDATE_DATA_GRID_SELECTED_ROW_INDEX', payload: rowIndx };
}

const updateDridSelectedCell = (selectedCellData) => {
  return { type: 'UPDATE_DATA_GRID_SELECTED_CELL', payload: selectedCellData };
}

const setCopyData = (copyData) => {
  return { type: 'UPDATE_COPY_DATA', payload: copyData };
}

// INIT GRID ROWS
export const fetchDataGridTree = (cloudTrackerService) => () => (dispatch, getState) => {
  dispatch(fetchAllProjectCases(cloudTrackerService)())
  .then((projectCases) => {
    const casesTree = buildTree(projectCases);
    dispatch(createDataGridRowsRequest(casesTree));
  })
};

// INIT GRID ROWS BY SPRINT
export const fetchDataGridTreeBySprint = (cloudTrackerService) => (sprintList=[]) => (dispatch) => {
  dispatch(fetchAllProjectCases(cloudTrackerService)())
  .then((projectCases) => {
    const casesTree = createSprintBacklogData(projectCases, sprintList);
    dispatch(createDataGridRowsRequest(casesTree));
  })
};

// INIT GRID ROWS BY USER ID
export const fetchUserCaseRows = (cloudTrackerService) => () => (dispatch, getState) => {
  dispatch(fetchUserCases(cloudTrackerService)())
  .then((projectCases) => {
    dispatch(createDataGridRowsRequest(projectCases));
  })
};

// SET GRID ROWS
export const updateDataGridRows = (data) => (dispatch) => {
  dispatch(updateStateDataGridRowsRequest(data));
};

// CREATE A NEW ROW AND THE EDIT AREA
export const createDataGridCase = (gridProps) => (dispatch, getState) => {
  const { dataGrid: { rows, expandedRows, selectedRowIndx=0, isSearchResult, isHoldingCreation }} = getState();
  const { onCellDoubleClick } = gridProps;

  if (isSearchResult || isHoldingCreation) return;

  let newRows = rows.slice();
  let newRowIndx = 0;
  const selectedCase = newRows.length > 0 ? newRows[selectedRowIndx] : null;

  // create the first case
  if (selectedCase === null) {
    newRows = [ { title: '' } ];
  }

  // case has 0 tree depth
  if (selectedCase && selectedCase.parentId === null) {
    newRowIndx = newRows.length;
    newRows = [ ...rows, { title: '' } ]
  }

  // count subcases of parent case for skip
  if (selectedCase && selectedCase.parentId) {
    const parentCaseIndx = _.findIndex(newRows, row => row.id === selectedCase.parentId);
    const parentCase = newRows[parentCaseIndx];
    if (!parentCase || !parentCase.subCases) return;

    const categoryTreeRows = countSkippedRows(parentCase, expandedRows);
    newRowIndx = parentCaseIndx + categoryTreeRows + 1;

    const newCase = {
      title: '',
      parentId: selectedCase.parentId,
      treeDepth: selectedCase.treeDepth,
      siblingIndex: selectedCase.siblingIndex,
      numberSiblings: selectedCase.numberSiblings,
    };

    newRows.splice(newRowIndx, 1, newCase);
  }

  dispatch(updateStateDataGridRowsRequest({ rows: newRows, expandedRows }));
  dispatch(setSelectedRowIndxRequest(newRowIndx));
  setTimeout(() => onCellDoubleClick({ idx: 1, rowIdx: newRowIndx }));
}

// UPDATE CELL CONTENT
export const updateDataGridCase = (cloudTrackerService) => (props) => (dispatch, getState) => {
	const { action, fromRow, updated, grid } = props;
  const { dataGrid: { rows, selectedRowIndx, isSearchResult } } = getState();

  let newRows = rows.slice();
  if (!newRows[fromRow] || action === "COPY_PASTE") return;

  if (rows[fromRow].id) {
    // update an exist case
    const caseId = rows[fromRow].id;
    newRows[fromRow] = { ...rows[fromRow], ...updated };

    if (!isSearchResult) {
      newRows = updateParentsSubCase(newRows, newRows[selectedRowIndx], selectedRowIndx);
    }
    dispatch(updateContentDataGridCaseRequest(newRows));
    dispatch(updateProjectCase(cloudTrackerService)({ caseId, updated }))
      .then(() => dispatch(updateContentDataGridCaseSuccess(newRows)))

  } else {
    // check a new case
    if (updated.title.trim() === '') {
      // clear empty case
      newRows.splice(selectedRowIndx, 1);
      dispatch(updateContentDataGridCaseSuccess(newRows));
      grid.selectCell({ idx: 1, rowIdx: selectedRowIndx - 1 });
    } else {
      // add new case
      const newCaseRow = { ...newRows[selectedRowIndx], ...updated };
      newCaseRow.parentId = newCaseRow.parentId === undefined ? null : newCaseRow.parentId;

      const newCase = _.pick(newCaseRow, ['title', 'parentId']);
      newRows[selectedRowIndx] = newCaseRow;
      
      dispatch(updateContentDataGridCaseRequest(newRows));
      dispatch(createProjectCase(cloudTrackerService)(newCase))
        .then((projectCase) => {
          const updatedRows = _.cloneDeep(newRows);
          updatedRows[selectedRowIndx].id = projectCase.id;

          if (!isSearchResult) {
            newRows = updateParentsSubCase(updatedRows, updatedRows[selectedRowIndx], selectedRowIndx);
          }
          dispatch(updateContentDataGridCaseSuccess(newRows));
        })
    }
  }
};

export const deleteDataGridCase = (cloudTrackerService) => (props) => (dispatch, getState) => {
  const { rows, selectedCell: { cursorCell, startCell } } = getState().dataGrid;

  if (cursorCell === undefined || startCell === undefined) return;

  const maxIdx = Math.max(cursorCell.rowIdx, startCell.rowIdx);
  const minIdx = Math.min(cursorCell.rowIdx, startCell.rowIdx);
  const selectedRows = [ ...rows.slice(minIdx, maxIdx + 1)];

  // get all tree with subcase ids of deleted cases
  let selectedIds = getSubCasesIds(selectedRows);

  // get row index of the last subcase id
  const lastIdx = _.findIndex(rows, row => row.id === selectedIds[selectedIds.length - 1]);

  dispatch(deleteProjectCase(cloudTrackerService)(selectedIds))
    .then(() => {
      const updatedRows = [
        ...rows.slice(0, minIdx),
        ...rows.slice(lastIdx + 1)
      ];

      let newRows;
      selectedRows.forEach((row) => {
        newRows = updateParentsSubCase(updatedRows, row, -1);
      })

      dispatch(updateContentDataGridCaseSuccess(newRows));
    })
}

export const searchDataGridCase = ({ searchText='' }) => (dispatch, getState) => {
  const { projectCaseList: { projectCases }, dataGrid: { expandedRows } } = getState();
  const newProjectCases = _.clone(projectCases);
  const clearSearchText = searchText.trim();

  // If the search is empty - build tree with subcases
  if (_.isEmpty(clearSearchText)) {
    const casesTree = buildTree(newProjectCases);
    dispatch(createDataGridRowsRequest(casesTree));
    return;
  }

  const filteredCases = caseFilterByText(newProjectCases, productBacklogGridColumns, clearSearchText);
  dispatch(updateStateDataGridRowsRequest({ rows: filteredCases, expandedRows: expandedRows, isSearchResult: true }));
}

// UPDATE SELECTED ROW INDEX BY CLICK
export const setSelectedRowIndx = (rowIdx) => (dispatch) => {
  dispatch(setSelectedRowIndxRequest(rowIdx));
};

// UPDATE A PARENT OF CASE
export const onDataGridKeyUp = (cloudTrackerService) => (props) => (dispatch, getState) => {
  const { key, shiftKey, grid } = props;
  const { projectCaseList: { projectCases }, dataGrid: { rows, expandedRows, selectedRowIndx, isSearchResult } } = getState();

  if (key !== 'Tab' || isSearchResult) return;

  const newRows = rows.slice();
  const newProjectCases = projectCases.slice();
  const newExpandedRows = { ...expandedRows };
  const selectedCase = newRows[selectedRowIndx];
  const newParentCaseId = shiftKey
    ? getHigherParentCaseId(newRows, selectedRowIndx)
    : getLowerParentCaseId(newRows, selectedRowIndx);

  if (newParentCaseId === selectedCase.parentId) return;

  const selectedProjectCaseIndx = _.findIndex(newProjectCases, row => row.id === selectedCase.id);
  newProjectCases[selectedProjectCaseIndx].parentId = newParentCaseId;
  newExpandedRows[newParentCaseId] = true;

  const newCasesTree = buildTree(newProjectCases);
  const newCasesRows = buildRows(newCasesTree, newExpandedRows);

  dispatch(updateStateDataGridRowsRequest({ rows: newCasesRows, expandedRows: newExpandedRows }));
  dispatch(updateProjectCase(cloudTrackerService)({ caseId: selectedCase.id, updated: { parentId: newParentCaseId }}));

  setTimeout(() => grid.metricsUpdated());
}

export const onCellExpand = (args) => (dispatch, getState) => {
  const { rowData: { id, treeDepth }, rows, expandedRows, rowIdx } = args;

  const newExpandedRows = _.cloneDeep(expandedRows);
  const newRows = _.cloneDeep(rows);
  const expandedSubRows = getExpandedSubRows(newRows[rowIdx], treeDepth, newExpandedRows);

  if (expandedRows && !expandedRows[id]) {
    newExpandedRows[id] = true;
    newRows.splice(rowIdx + 1, 0, ...expandedSubRows);
  } else if (expandedRows[id]) {
    newExpandedRows[id] = false;
    newRows.splice(rowIdx + 1, expandedSubRows.length);
  }

  dispatch(updateStateDataGridRowsRequest({ rows: newRows, expandedRows: newExpandedRows }));
};


export const handleCopy = (e) => (dispatch, getState) => {
  const { copyData } = getState().dataGrid;
  const selectedClass = document.activeElement.className;
  if (selectedClass !== 'rdg-selected' && selectedClass.indexOf('react-contextmenu-item') === -1) return;

  e.clipboardData.setData('text/plain', copyData);
  e.preventDefault();
}

export const handleKeydown = (e) => (dispatch, getState) => {
  const { rows, selectedCell } = getState().dataGrid;
  const selectedElement = document.activeElement;
  
  if (e.ctrlKey === true && (e.which === 67 || e.which === 86) && selectedElement.className === 'rdg-selected') {
    e.preventDefault();

    const copyData = createCopyData(rows, selectedCell);
    dispatch(setCopyData(copyData));

    document.execCommand('copy');
 }
}

export const onCellRangeSelection = (props) => (dispatch, getState) => {
  const { startCell, cursorCell } = props;
  const newSelectedCell = { startCell, cursorCell };
  dispatch(updateDridSelectedCell(newSelectedCell));
}

export const isSelectedSingleCell = () => (dispatch, getState) => {
  const { selectedCell: { startCell, cursorCell } } = getState().dataGrid;
  return startCell && cursorCell && startCell.rowIdx === cursorCell.rowIdx && startCell.idx === cursorCell.idx;
}

export const onCaseOpen = () => (dispatch, getState) => {
  const { rows, selectedRowIndx } = getState().dataGrid;
  const selectedCase = rows[selectedRowIndx];

  if (selectedCase === undefined || !selectedCase.numericId) return;
  dispatch(openProjectCase({ caseId: selectedCase.numericId }));
}

export const onRowCopy = (e) => (dispatch, getState) => {
  const { rows, selectedCell } = getState().dataGrid;

  const copyData = createCopyData(rows, selectedCell);
  dispatch(setCopyData(copyData));

  document.execCommand('copy');
}

export const onRowCopyTitle = (e) => (dispatch, getState) => {
  const { rows, selectedCell } = getState().dataGrid;

  const copyData = createCopyData(rows, selectedCell, ['numericId', 'title'], true);
  dispatch(setCopyData(copyData));

  document.execCommand('copy');
}

export const sortRows = (sortColumn, sortDirection, isTree=false)  => (dispatch, getState) => {
  const {dataGrid: { expandedRows }, projectCaseList: { projectCases }} = getState();
  let categoryRateList = {};
  let isSearchResult = false;

  const currentPropertyList = caseFormPropertiesData['case' + _.startCase(sortColumn) +'List'] || [];
  if (currentPropertyList.length && currentPropertyList[0].hasOwnProperty('rate')) {
    categoryRateList = _.mapKeys(currentPropertyList, 'value');
  };
  
  const comparer = (a, b) => {
    if (sortDirection === "ASC") {
      if (_.isEmpty(categoryRateList)) return a[sortColumn] > b[sortColumn] ? 1 : -1;
      const valueA = a[sortColumn] ? categoryRateList[a[sortColumn]].rate : -1;
      const valueB = b[sortColumn] ? categoryRateList[b[sortColumn]].rate : -1;
      return valueA > valueB ? 1 : -1;

    } else if (sortDirection === "DESC") {
      if (_.isEmpty(categoryRateList)) return a[sortColumn] < b[sortColumn] ? 1 : -1;
      const valueA = a[sortColumn] ? categoryRateList[a[sortColumn]].rate : -1;
      const valueB = b[sortColumn] ? categoryRateList[b[sortColumn]].rate : -1;
      return valueA < valueB ? 1 : -1;
    }
  };

  let newRows = [...projectCases];

  if (sortDirection === "NONE" && isTree) {
    const newCasesTree = buildTree([...projectCases]);
    newRows = buildRows(newCasesTree, expandedRows);
  } else {
    newRows = [...projectCases].sort(comparer);
    isSearchResult = true;
  }

  dispatch(updateStateDataGridRowsRequest({ rows: newRows, expandedRows: expandedRows, isSearchResult }));
};

const createCopyData = (rows, selectedCell, columns, withLink=false) => {
  const { startCell, cursorCell } = selectedCell;
  let selectedRows;
  let copyData = '';

  if (startCell.rowIdx <= cursorCell.rowIdx ) {
    selectedRows = [
      ...rows.slice(startCell.rowIdx, cursorCell.rowIdx + 1)
    ]
  } else {
    selectedRows = [
      ...rows.slice(cursorCell.rowIdx, startCell.rowIdx + 1)
    ]
  }

  if (!selectedRows.length) return null;
  if (columns === undefined) {
    columns = _.map(productBacklogGridColumns, 'key');
  }

  selectedRows.map((row) => {
    const filteredRow = _.pick(row, columns);

    _.mapKeys(filteredRow, (value, key) => {
      if (value === undefined) return;
      copyData += value + '\t';
    })

    if (withLink === true) {
      copyData += window.location.origin + '/project-cases/' + row.numericId;
    }
    
    copyData += '\n';
    return row;
  })

  return copyData;
}
