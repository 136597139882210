import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import SignedInLink from './SignedInLink';
import SignedOutLink from './SignedOutLink';

const Navbar = (props) => {
	const { auth, profile } = props;
	const links = auth.uid ? <SignedInLink auth={auth} profile={profile}/> : <SignedOutLink />;

	return (
		<div>
			<nav className="nav-wrapper teal darken-2"> 
				<div className="container">
					<Link to='/' className="header-brand-logo">
						<img alt='logo' width="35" height="35" src="images/logo.png"></img>
						Cloud Tracker
					</Link>
					{links}
				</div>
			</nav>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		profile: state.firebase.profile
	}
}

export default connect(mapStateToProps)(Navbar);
