const initState = {
	projectCases: [],
	loading: true,
	holdingNewCase: false,
	error: null
};

const projectCaseListReducer = (state = initState, action) => {

	switch (action.type) {
		case 'FETCH_PROJECT_CASES_REQUEST':
			return initState;

		case 'FETCH_PROJECT_CASES_SUCCESS':
			return {
				projectCases: action.payload,
				loading: false,
				holdingNewCase: false
			};

		case 'FETCH_PROJECT_CASES_FAILURE': 
			return {
				projectCases: [],
				loading: false,
				error: action.payload
			};

		case 'UPDATE_PROJECT_CASE_REQUEST':
			return {
				...state, 
				projectCases: action.payload,
				holdingNewCase: false
			};

		case 'UPDATE_PROJECT_CASE_SUCCESS':
			return {
				...state, 
				projectCases: action.payload,
				holdingNewCase: false
			};

		case 'UPDATE_PROJECT_CASE_FAILURE':
			console.error(action.payload);
			return { 
				...state, 
				error: true
			};

		case 'CREATE_PROJECT_CASE_REQUEST':
			return { 
				...state, 
				projectCases: action.payload,
				holdingNewCase: true
			};

		default:
			return state;
	}
}

export default projectCaseListReducer;
