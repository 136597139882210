const firebase = require('firebase/app');
require('firebase/functions');
require('firebase/firestore');
require('firebase/auth');
  
// Initialize Firebase
var config = {
    apiKey: "AIzaSyCNghaNXNyAB1MibaqE6ovB0qpupTsi_Zs",
    authDomain: "thecloudtracker.firebaseapp.com",
    databaseURL: "https://thecloudtracker.firebaseio.com",
    projectId: "thecloudtracker",
    storageBucket: "thecloudtracker.appspot.com",
    messagingSenderId: "464983280538",
    appId: "1:464983280538:web:15dd027512139e3d"
};

firebase.initializeApp(config);
firebase.functions();
firebase.firestore();

module.exports = firebase;