import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="center">
      <h5>Page Not Found</h5>
    </div>
  );
}

export default NotFoundPage;