import React, {Component} from 'react';
import _ from 'lodash';
import M from "materialize-css";

class ProjectCaseCardSelectedPropsContainer extends Component {

  componentDidMount() {
    this.updateMaterialize();
  }

  componentDidUpdate = () => {
   this.updateMaterialize()
  }

  updateMaterialize = () => {
    M.FormSelect.init(document.querySelectorAll('select'), {});
  }

  render() {
    const { formData, formPropertiesData, handleChange } = this.props;

    return (
      <ProjectCaseCardSelectedProps 
        formData={formData}
        formPropertiesData={formPropertiesData}
        handleChange={handleChange}
      /> 
    )
  }
}

class ProjectCaseCardSelectedProps extends Component  {

  render() {
    const { formData, formPropertiesData, handleChange } = this.props;

    const {
      caseStatusList, 
      casePriorityList, 
      caseTypeList, 
      caseSprintList, 
      caseReleaseList, 
      caseComponentList, 
      userList 
    } = formPropertiesData; 

    const renderSelectField = (keyField, options=[], optionsMap={ value: 'value', label: 'value'}) => {
      const label = _.capitalize(keyField);
      const key = _.camelCase(keyField);
      const multipleFields = ['assigned to'];

      let selectedValue = options.find(opt => formData[key] === opt[optionsMap.value]) || '';


    
      return (
        <div className="row form-select-field">
          <div className="col s4 m4 center-align label">
            <p className="grey lighten-4 grey-text text-darken-2 form-property-label">{label}</p>
          </div>
          <div className="col marker">
            <p className={selectedValue.color || ''}></p>
          </div>
          <div className="col s7 m7">
            <select id={key} multiple={multipleFields.indexOf(keyField) !== -1} defaultValue={formData[key]} onChange={(props) => handleChange(props, formData[key])}>
              <option value='' disabled>Choose {label}</option>
              {options.map((option) => {
                const markerLink = option.marker ? '../images/icons/' + option.marker : null;
                return (
                  <option
                    key={option.id}
                    value={option[optionsMap.value]}
                    data-icon={markerLink}
                  >{option[optionsMap.label]}</option>
                )
              })}
            </select>
          </div>
        </div>
      )
    }

    return (
      <div>
        {renderSelectField('assigned to', userList, { value: 'id', label: 'fullName'})}
        {renderSelectField('status', caseStatusList)}

        <div className="divider"></div>
        {renderSelectField('priority', casePriorityList)}
        {renderSelectField('type', caseTypeList)}

        <div className="divider"></div>
        {renderSelectField('sprint', caseSprintList)}
        {renderSelectField('release', caseReleaseList)}

        <div className="divider"></div>
        {renderSelectField('component', caseComponentList)}
      </div>
    )
  }
}

export default ProjectCaseCardSelectedPropsContainer;