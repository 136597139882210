import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AssignedToFormatter extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  };

  state = {
    selectedUsers: ''
  }

  componentDidMount() {
    if (!this.props.value) return;

    const selectedUserArray = this.props.value.map((userId) => {
      const user = this.props.options.find((u) => u.id === userId);
      return user !== undefined ? user.title : '';
    });

    const selectedUsers = selectedUserArray.join(', ');
    this.setState({ selectedUsers });
  }

  render() {
    const { selectedUsers } = this.state;
    const elementStyle = {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }

    return (<div title={selectedUsers} style={elementStyle}>{selectedUsers}</div>)
  }
}

export default AssignedToFormatter;