import React from 'react';
import { CloudTrackerServiceConsumer } from '../context';

const withCloudTrackerService = () => (Wrapped) => {

  return (props) => {
    return (
      <CloudTrackerServiceConsumer>
        {
          (cloudTrackerService) => {
            return (<Wrapped {...props}
                     cloudTrackerService={cloudTrackerService}/>);
          }
        }
      </CloudTrackerServiceConsumer>
    );
  }
};

export default withCloudTrackerService;
