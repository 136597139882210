import history from '../../history';
import AuthService from '../../services/authService'

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' })
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err })
    })
  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS'})
        history.push('/signin');
    })
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    return firebase.auth().createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        return firestore.collection('users').doc(resp.user.uid).set({
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          initials: newUser.firstName[0] + newUser.lastName[0],
          verified: false
        })
      })
      .then((resp) => {
        firebase.auth().currentUser.sendEmailVerification();
        dispatch({ type: 'SIGNUP_SUCCESS' })
      }).catch((err) => {
        return dispatch({ type: 'SIGNUP_ERROR', err })
      })
  }
}

export const signUpAsAdmin = (newUser) => (dispatch, getState) => {
  return dispatch(signUp(newUser))
    .then(() => {
      const { createAdmin } = new AuthService();
      createAdmin();
    })
}

export const checkAppAdmin = () => (dispatch, getState) => {
  const { checkAppAdmin } = new AuthService();
  return checkAppAdmin();
}