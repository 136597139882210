import React from 'react';
import { withAuthentication } from '../hoc';
import EditProjectCaseCardContainer from '../containers/EditProjectCaseCardContainer';

const EditProjectCasePage = ({ match }) =>
	<div className="container">
		<EditProjectCaseCardContainer match={match} />
	</div>

export default withAuthentication(EditProjectCasePage);
