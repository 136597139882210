import React, { Component } from 'react';
import ReactDataGrid from 'react-data-grid';
import FloatButton from '../FloatButton';
import SearchBar from '../SearchBar';
import { Menu } from "react-data-grid-addons";
import { ModalDeleteNotification } from '../modalNotifications'

class ProductBacklogDataGrid extends Component {
  render() {
    const { ContextMenu, MenuItem, ContextMenuTrigger } = Menu;
    const { 
      productBacklogGridColumns,
      updateDataGridCase,
      onDataGridKeyUp,
      setSelectedRowIndx,
      onCellExpand,
      getSubRowDetails,
      createDataGridCase,
      deleteDataGridCase,
      searchDataGridCase,
      expandedRows,
      onCellRangeSelection,
      isSelectedSingleCell,
      onCaseOpen,
      onRowCopy,
      onRowCopyTitle,
      sortRows,
      rows
    } = this.props;
    
    const ProductBacklogContextMenu = ({ id, onCaseOpen, onRowCopy, onRowCopyTitle, isSelectedSingleCell }) => {
      return (
        <ContextMenu id={id}>
          { isSelectedSingleCell() ? (
            <React.Fragment>
              <MenuItem onClick={onCaseOpen}>
                Edit
              </MenuItem>
              <MenuItem divider/>
          </React.Fragment>
          ) : null }
          <MenuItem onClick={onRowCopy}>
            Copy
          </MenuItem>
          <MenuItem onClick={onRowCopyTitle}>
            Copy Title with Link
          </MenuItem>
          <a href="#modalCaseDelete" className="modal-trigger">
          <MenuItem divider/>
            <MenuItem>
              Delete
            </MenuItem>
          </a>
        </ContextMenu>
      );
    }

    return (
      <div className="item-list section">
        <div className="ctr-data-grid product-backlog-data-grid">
          <ModalDeleteNotification onAction={deleteDataGridCase} />
          <SearchBar searchAction={searchDataGridCase}/>
          <ReactDataGrid
            columns={productBacklogGridColumns}
            rowGetter={i => rows[i]}
            rowsCount={rows.length}
            minHeight={800}
            onGridRowsUpdated={(props) => updateDataGridCase({ ...props, grid: this.grid })}
            onGridKeyUp={(props) => onDataGridKeyUp({ ...props, grid: this.grid })}
            enableCellSelect={true}
            ref={grid => this.grid = grid}
            getSubRowDetails={getSubRowDetails(expandedRows)}
            onCellExpand={args => onCellExpand({ ...args, rows, expandedRows })}
            onCellSelected={({ rowIdx }) => { setSelectedRowIndx(rowIdx) }}
            cellNavigationMode={'loopOverRow'}
            RowsContainer={ContextMenuTrigger}
            contextMenu={
              <ProductBacklogContextMenu
                onCaseOpen={onCaseOpen}
                onRowCopy={onRowCopy} 
                onRowCopyTitle={onRowCopyTitle}
                isSelectedSingleCell={isSelectedSingleCell}
              />
            }
            cellRangeSelection={{
              onStart: onCellRangeSelection,
              onComplete: onCellRangeSelection
            }}
            onGridSort={(sortColumn, sortDirection) => sortRows(sortColumn, sortDirection, true)}
          />
        </div>
        <FloatButton addNewCase={() => createDataGridCase(this.grid)}/>
      </div>
    )
  } 
}

export default ProductBacklogDataGrid;