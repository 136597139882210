export const fetchUnconfirmedUsers = (cloudTrackerService) => (props) => (dispatch) => {
  return cloudTrackerService.getUnconfirmedUserList();
}

export const confirmUser = (cloudTrackerService) => (uid) => (dispatch) => {
  return cloudTrackerService.confirmNewUser(uid);
}

export const deleteUser = (cloudTrackerService) => (uid) => (dispatch) => {
  return cloudTrackerService.deleteUser(uid);
}
