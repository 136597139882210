import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import fbConfig from './config/fbConfig';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import { CloudTrackerServiceProvider } from './components/context';
import CloudTrackerService from './services/cloudTrackerService';
import './styles';

const store = createStore(rootReducer, 
	compose(
		applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
		reduxFirestore(fbConfig),
		reactReduxFirebase(fbConfig, { useFirestoreForProfile: true, userProfile: 'users', attachAuthIsReady: true })
	)
);

const cloudTrackerService = new CloudTrackerService();

store.firebaseAuthIsReady.then(() => {
	ReactDOM.render(
		<Provider store={store}>
			<ErrorBoundary>
				<CloudTrackerServiceProvider value={cloudTrackerService}>
					<App />
				</CloudTrackerServiceProvider>
			</ErrorBoundary>
		</Provider>,
		document.getElementById('root')
	);
})
