import React, { Component } from 'react';

const SignUpForm = ({ handleSubmit, handleChange, authError, title }) =>
  <div className="container">
    <div className="row">
      <div className="col s6 m6 offset-m3">
        <form onSubmit={handleSubmit} className="white">
          <h5 className="grey-text text-darken-3">{ title ? title: 'Sign Up'}</h5>
          <div className="input-field">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" onChange={handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" onChange={handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" autoсomplete="username" onChange={handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" onChange={handleChange}/>
          </div>
          <div className="input-field">
            <button className="btn pink darken-2 z-depth-0">Sign Up</button>
            <div className="red lighten-5 pink-text text-darken-2 center">
              { authError ? <p>{authError}</p> : null }
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>


class SignUpFormContainer extends Component {

  state = {
		email: '',
    password: '',
    firstName: '',
    lastName: ''
  }

  render() {
    const { submitAction, title, authError } = this.props;

    const handleChange = (e) => {
      this.setState({
        [e.target.id]: e.target.value
      })
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      submitAction(this.state);
    }

    return (
			<SignUpForm
        title={title}
				handleChange={handleChange}
				handleSubmit={handleSubmit}
				authError={authError}
			/>
    )
  }
}

export default SignUpFormContainer;