import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux';
import { signOut } from '../../store/actions/authActions';
import { withRoles } from '../hoc';

const SignedInLink = ({ signOut, roles: { admin }, profile: { initials }}) => {
	return (
		<ul className="right">
			{	admin ? <li><NavLink to='/admin'>ADMIN</NavLink></li> : null }
			<li><NavLink to='/'>TO DO LIST</NavLink></li>
			<li><NavLink to='/create'>CREATE</NavLink></li>
			<li><NavLink to='/product-backlog'>PRODUCT BACKLOG</NavLink></li>
			<li><NavLink to='/sprint-backlog'>SPRINT BACKLOG</NavLink></li>
			<li><a href='/' onClick={signOut}>LOG OUT</a></li>
			<li><NavLink to='/' className='btn btn-floating pink darken-2 initials-ico'>{initials}</NavLink></li>
		</ul>
	);
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
		signOut: signOut,
  }, dispatch)
}

export default compose(
	withRoles,
	connect(null, mapDispatchToProps)
)(SignedInLink);