import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { withCloudTrackerService } from '../hoc';
import { fetchConfirmedUserList } from '../../store/actions/userActions';
import { onCellExpand, fetchDataGridTree, createDataGridCase, updateDataGridCase, deleteDataGridCase, searchDataGridCase, sortRows,
         updateDataGridRows, setSelectedRowIndx, onDataGridKeyUp, handleCopy, handleKeydown, onCellRangeSelection, isSelectedSingleCell, onCaseOpen, onRowCopy, onRowCopyTitle } from '../../store/actions/dataGridActions';
import { getSubRowDetails } from '../../services/dataGridService';
import { caseStatusList, casePriorityList, caseTypeList, caseSprintList, caseReleaseList, caseComponentList } from '../../config/constants';
import { Editors } from "react-data-grid-addons";
import PreloaderCircular from '../PreloaderCircular';
import ErrorIndicator from '../ErrorIndicator';
import ProductBacklogDataGrid from '../dataGrids/ProductBacklogDataGrid';
import { AssignedToFormatter } from '../dataGrids/formatters';

class ProductBacklogDataGridContainer extends Component {
  _isMounted = false;

  state = {
    userListAsOptions: [],
    userLoading: true
  }

  constructor(props) {
    super(props);
    document.addEventListener('keydown', props.handleKeydown, true);
    document.addEventListener('copy', props.handleCopy, true);
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.fetchDataGridTree();
    this.props.fetchUserList()
      .then(({ userListAsOptions }) => {
        if (!this._isMounted) return;
        this.setState({ userListAsOptions, userLoading: false })
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.updateDataGridRows();
  }

  render() {
    const { DropDownEditor, SimpleTextEditor } = Editors;
    const { userListAsOptions, userLoading } = this.state;
    const { loading, error, rows, onCellExpand, expandedRows, createDataGridCase, updateDataGridCase, searchDataGridCase, sortRows,
            deleteDataGridCase, onDataGridKeyUp, setSelectedRowIndx, onCellRangeSelection, isSelectedSingleCell, onCaseOpen, onRowCopy, onRowCopyTitle } = this.props;

    if (loading || userLoading) { return <PreloaderCircular />; }
    if (error) { return <ErrorIndicator />; }

    const productBacklogGridColumns = [
      { key: 'numericId', name: 'Id', width: 55, sortable: true },
      { key: 'title', name: 'Title', editor: <SimpleTextEditor />, width: 350, sortable: true },
      { key: 'status', name: 'Status', editor: <DropDownEditor options={caseStatusList} />, sortable: true },
      { key: 'priority', name: 'Priority', editor: <DropDownEditor options={casePriorityList} />, sortable: true },
      { key: 'type', name: 'Type', editor: <DropDownEditor options={caseTypeList} /> },
      { key: 'sprint', name: 'Sprint', editor: <DropDownEditor options={caseSprintList} />, sortable: true },
      { key: 'release', name: 'Release', editor: <DropDownEditor options={caseReleaseList} />, sortable: true },
      { key: 'component', name: 'Component', editor: <DropDownEditor options={caseComponentList} /> },
      { key: 'assignedTo', name: 'Assigned to', width: 150, formatter: <AssignedToFormatter options={userListAsOptions} /> }
    ];

    return (
      <ProductBacklogDataGrid
        productBacklogGridColumns={productBacklogGridColumns}
        onCellExpand={onCellExpand}
        rows={rows}
        expandedRows={expandedRows}
        getSubRowDetails={getSubRowDetails}
        updateDataGridCase={updateDataGridCase}
        onDataGridKeyUp={onDataGridKeyUp}
        createDataGridCase={createDataGridCase}
        deleteDataGridCase={deleteDataGridCase}
        searchDataGridCase={searchDataGridCase}
        setSelectedRowIndx={setSelectedRowIndx}
        onCellRangeSelection={onCellRangeSelection}
        isSelectedSingleCell={isSelectedSingleCell}
        onCaseOpen={onCaseOpen}
        onRowCopy={onRowCopy}
        sortRows={sortRows}
        onRowCopyTitle={onRowCopyTitle}
      />)
  }
}

const mapStateToProps = ({ projectCaseList: { projectCases, loading, error }, dataGrid: { rows, expandedRows } }) => {
  return { projectCases, loading, error, rows, expandedRows }
};

const mapDispatchToProps = (dispatch, { cloudTrackerService }) => {
  return bindActionCreators({
    fetchDataGridTree: fetchDataGridTree(cloudTrackerService),
    fetchUserList: fetchConfirmedUserList(cloudTrackerService),
    createDataGridCase: createDataGridCase,
    deleteDataGridCase: deleteDataGridCase(cloudTrackerService),
    searchDataGridCase: searchDataGridCase,
    updateDataGridRows: updateDataGridRows,
    setSelectedRowIndx: setSelectedRowIndx,
    onCellExpand: onCellExpand,
    onDataGridKeyUp: onDataGridKeyUp(cloudTrackerService),
    updateDataGridCase: updateDataGridCase(cloudTrackerService),
    onCellRangeSelection: onCellRangeSelection,
    isSelectedSingleCell: isSelectedSingleCell,
    onCaseOpen: onCaseOpen,
    onRowCopy: onRowCopy,
    onRowCopyTitle: onRowCopyTitle,
    handleCopy: handleCopy,
    handleKeydown: handleKeydown,
    sortRows: sortRows
  }, dispatch)
}

export default compose(
  withCloudTrackerService(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductBacklogDataGridContainer);