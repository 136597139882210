import _ from 'lodash';

export const fetchConfirmedUserList = (cloudTrackerService) => () => (dispatch) => {
  return cloudTrackerService.getUserList()
    .then((userList) => { 
      const userListAsOptions = [];

      userList.forEach(user => {
        userListAsOptions.push({ id: user.id, value: user.id, text: user.fullName, title: user.fullName })
      })
      
      return { userList, userListAsOptions };
    })
    .catch((err) => err)
}
