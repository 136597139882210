export const CREATE_PROJECT_CASE_PAGE_TITLE = 'CREATE A NEW CASE'
export const EDIT_PROJECT_CASE_PAGE_TITLE = 'EDIT THE CASE'
export const CREATE_PROJECT_CASE_PAGE_ACTION = 'CREATE'
export const EDIT_PROJECT_CASE_PAGE_ACTION = 'EDIT'

export const casePriorityList = [
  { id: 3, rate: 3, value: 'Very high', color: 'red', marker: 'priority-marker-red.png' },
  { id: 2, rate: 2, value: 'High', color: 'orange', marker: 'priority-marker-orange.png' },
  { id: 1, rate: 1, value: 'Medium', color: 'yellow', marker: 'priority-marker-yellow.png' },
  { id: 0, rate: 0, value: 'Low', color: 'green', marker: 'priority-marker-green.png' }
]

export const caseStatusList = [
  { id: 3, rate: 3, value: 'Completed' },
  { id: 2, rate: 2, value: 'In progress' },
  { id: 1, rate: 1, value: 'Not done' },
  { id: 0, rate: 0, value: 'Blocked' }
]

export const caseTypeList = [
  { id: 3, rate: 3, value: 'Bug fixes' },
  { id: 2, rate: 2, value: 'Improvement' },
  { id: 1, rate: 1, value: 'New Feature' },
  { id: 0, rate: 0, value: 'UX Design' }
]

export const caseSprintList = [
  { id: 3, rate: 3, value: 'Sprint 4', startSt: new Date(2019, 4, 1), endAt: new Date(2019, 4, 15), cases: [] },
  { id: 2, rate: 2, value: 'Sprint 3', startSt: new Date(2019, 3, 1), endAt: new Date(2019, 3, 15), cases: [] },
  { id: 1, rate: 1, value: 'Sprint 2', startSt: new Date(2019, 2, 1), endAt: new Date(2019, 2, 15), cases: [] },
  { id: 0, rate: 0, value: 'Sprint 1', startSt: new Date(2019, 1, 1), endAt: new Date(2019, 1, 15), cases: [] }
]

export const caseReleaseList = [
  { id: 5, rate: 5, value: 'Release v2.2' },
  { id: 4, rate: 4, value: 'Release v2.1' },
  { id: 3, rate: 3, value: 'Release v2.0' },
  { id: 2, rate: 2, value: 'Release v1.2' },
  { id: 1, rate: 1, value: 'Release v1.1' },
  { id: 0, rate: 0, value: 'Release v1.0' }
]

export const caseComponentList = [
  { id: 4, value: 'Single Case' },
  { id: 3, value: 'Case List' },
  { id: 2, value: 'Editing  cases' },
  { id: 1, value: 'Product Backlog' },
  { id: 0, value: 'Sprint Backlog' }
]

export const productBacklogGridColumns = [
  { key: 'numericId', name: 'Id', width: 55, sortable: true },
  { key: 'title', name: 'Title', editorType: 'input', width: 350, sortable: true },
  { key: 'status', name: 'Status', editorType: 'select', selectList: caseStatusList, sortable: true },
  { key: 'priority', name: 'Priority', editorType: 'select', selectList: casePriorityList, sortable: true },
  { key: 'type', name: 'Type', editorType: 'select', selectList: caseTypeList },
  { key: 'sprint', name: 'Sprint', editorType: 'select', selectList: caseSprintList, sortable: true },
  { key: 'release', name: 'Release', editorType: 'select', selectList: caseReleaseList, sortable: true },
  { key: 'component', name: 'Component', editorType: 'select', selectList: caseComponentList }
];

export const sprintBacklogGridColumns = [
  { key: 'title', name: 'Title', width: 350 },
  { key: 'status', name: 'Status' },
  { key: 'priority', name: 'Priority' },
  { key: 'type', name: 'Type' },
  { key: 'sprint', name: 'Sprint' },
  { key: 'release', name: 'Release' },
  { key: 'component', name: 'Component' }
];

export const toDoListGridColumns = [
  { key: 'title', name: 'Title', width: 350, sortable: true },
  { key: 'status', name: 'Status', sortable: true },
  { key: 'priority', name: 'Priority', sortable: true } 
];

export const projectCaseScheme = {
  title: '',
  comments: '',
  assignedTo: [],
  status: '',
  priority: '',
  type: '',
  sprint: '',
  release: '',
  component: ''
}
