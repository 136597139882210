import { createProjectCase, updateProjectCase } from './projectCaseActions';
import * as caseFormPropertiesData from '../../config/constants';
import history from '../../history';

export const createProjectCaseForm = (cloudTrackerService) => () => (dispatch) => {
  return cloudTrackerService.getUserList()
    .then((userList) => ({ projectCase: caseFormPropertiesData.projectCaseScheme, userList }))
    .catch((err) => err)
}

export const fetchProjectCaseForm = (cloudTrackerService) => (numericId) => (dispatch, getState) => {
  return Promise.all([
    cloudTrackerService.getCaseByNumericId(numericId),
    cloudTrackerService.getUserList()])
      .then(([projectCase, userList]) => ({ projectCase, userList }))
      .catch((err) => err)
}

export const createProjectCaseByForm = (cloudTrackerService) => (projectCase) => (dispatch, getState) => {
  return dispatch(createProjectCase(cloudTrackerService)(projectCase))
    .then(() => history.goBack())
}

export const editProjectCaseByForm = (cloudTrackerService) => (projectCase) => (dispatch, getState) => {
  return dispatch(updateProjectCase(cloudTrackerService)({ caseId: projectCase.id, updated: { ...projectCase} }))
    .then(() => history.goBack())
}

export const checkValidFormData = (projectCase) => (dispatch, getState) => {
	return projectCase.title.length !== 0;
}

