import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { signUpAsAdmin, checkAppAdmin } from '../../store/actions/authActions';
import PreloaderCircular from '../PreloaderCircular';
import SignUpFormContainer from './SignUpForm';

export class SignUpAsAdmin extends Component {

	state= {
		adminExists: true,
		loading: true
	}

	componentDidMount() {
		this.props.checkAppAdmin()
			.then(({ data }) => {

				const newState = { loading: false };

				if (data.adminExists === false) {
					newState.adminExists = false;
				}
				
				this.setState(newState);
			})
	}

	render() {
		const { auth, signUpAsAdmin, authError } = this.props;
		const { loading, adminExists } = this.state;

		console.log('this state', this.state)
		if (loading) return <PreloaderCircular />;
		if (adminExists === true) return <Redirect to='/signin'/>;
		if (auth.uid) return <Redirect to='/' />

		return (
			<SignUpFormContainer
        title={'Create Admin User'}
				submitAction={signUpAsAdmin}
				authError={authError}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.firebase.auth,
		authError: state.auth.authError
	}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
		signUpAsAdmin: signUpAsAdmin,
		checkAppAdmin: checkAppAdmin
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpAsAdmin);
